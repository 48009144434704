<template>
  <div class="footer">
    <div class="w1200">
      <div class="footer-flex">
        <div class="footer-item flex2">
          <div class="footer-item-title">小微之春</div>
          <div class="footer-item-content">
            <p>公司地址： 北京市海淀区信息路甲28号12层D座12B</p>
            <p>
              主营业务： 企业贷款咨询、技术服务、计算机系统服<br />务、数据处理服务等
            </p>
          </div>
        </div>
        <div class="footer-item flex2">
          <div class="footer-item-title">了解小微之春</div>
          <div class="footer-item-content">
            <p class="cursor" @click="go('/about')">小微之春简介</p>
            <p class="cursor" @click="go('/news')">公告新闻</p>
          </div>
        </div>
        <!-- <div class="footer-item flex2">
                    <div class="footer-item-title">帮助与支持</div>
                    <div class="footer-item-content">
                        <p>常见问题</p>
                        <p>贷款计算器</p>
                    </div>
                </div> -->
        <div class="footer-item flex-center">
          <div class="footer-item-title">关注我们</div>
          <div class="footer-item-img">
            <img :src="require(`@/assets/img/gzh.jpg`)" />
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >京ICP备2022019664号-1 小微之春ICP备案号 Copyright © 2022
            小微之春</a
          >
        </p>
        <!-- <p>京公网安备 11010802022644号</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-footer",
  data() {
    return {};
  },
  methods: {
    go(url) {
      this.$router.push({
        path: url,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  background: url("@/assets/img/img_dbbj.png");
  background-size: 100% 100%;
  .w1200 {
    width: 1200px;
    margin: 59px auto 0;
    .footer-flex {
      display: flex;
      justify-content: space-between;

      .footer-item {
        &.flex-center {
          .footer-item-title {
            margin-left: 5px;
          }
        }
        .footer-item-title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 23px;
        }
        .footer-item-content {
          margin-top: 25px;
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 23px;
            &.cursor {
              cursor: pointer;
            }
          }
        }
        .footer-item-img {
          // margin-top: 8px;
          img {
            width: 89px;
            height: 89px;
          }
        }
      }
    }
    .footer-bottom {
      border-top: 2px solid rgba(153, 153, 153, 0.21);
      margin: 50px auto 54px;
      text-align: center;
      padding-top: 48px;
      p {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 23px;
        a {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 23px;
        }
      }
    }
  }
}
</style>
